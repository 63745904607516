import {
    Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import React, {ChangeEvent, useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormLabel from '@mui/material/FormLabel/FormLabel';
import {store} from 'shared-components/src/app/store';
import {getDosageCalculator} from 'shared-components/src/features/orderSlice';
import UnderConstruction from 'shared-components/src/ui/UnderConstruction';
import CalculatorOptions from "./CalculatorOptions";

interface DosageCalculatorProps {
    orderId: string;
}

const DosageCalculator: React.VFC<DosageCalculatorProps> = ({orderId}) => {
    const calculator = getDosageCalculator(store.getState(), orderId);
    const [title, setTitle] = useState('');

    const handleTitleUpdate = (newTitle: string) => {
        setTitle(newTitle);
    };

    const changeCalculator = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        alert(e.target.value);
    };
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="applicationFormHeader"
            >
                <Typography component="span">
                    Dosisrechner{title ? `: ${title}` : ''}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid xs={12} style={{textAlign: 'left'}}>
                        <CalculatorOptions onTitleUpdate={handleTitleUpdate}/>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default DosageCalculator;
