import React from 'react';
import UnderConstruction from 'shared-components/src/ui/UnderConstruction';

const DiagramPlayground: React.FC = () => {
    return (
        <div>
            <UnderConstruction />
        </div>
    );
}

export default DiagramPlayground;
