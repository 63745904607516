import {
  SelectChangeEvent, Grid,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import CwTextField from 'shared-components/src/ui/CwTextField';
import ApplicationForm from './ApplicationForm';
import DosageCalculator from './DosageCalculator';
import { useAppDispatch } from 'shared-components/src/app/hooks';
import { store } from 'shared-components/src/app/store';
import { selectTitle, setTitle, deleteOrder } from 'shared-components/src/features/orderSlice';
import CwLightButton from 'shared-components/src/ui/CwLightButton';
import { OrderType } from 'shared-components/src/interfaces/Enums';
import Dosage from './Dosage';
import OrderCosts from './OrderCosts';
import OrderItemGrid from "./OrderItemGrid";
import ArticleSearchComponent from "shared-components/src/ui/ArticleSearchComponent";
import {ArticleService} from "../services/ArticleService";
import {DropResult} from "@hello-pangea/dnd";

interface OrderProps {
  id: string;
  orderType: OrderType;
}

const Order: React.FC<OrderProps> = ({ id, orderType }) => {
  const dispatch = useAppDispatch();

  const [articles, setArticles] = useState([
    {
      amount: 10,
      articleNumber: 'A001',
      articleName: 'Article 1',
      pinYin: 'Pin Yin 1',
      chinese: 'Chinese 1',
      price: 9.99,
    },
    {
      amount: 20,
      articleNumber: 'A002',
      articleName: 'Article 2',
      pinYin: 'Pin Yin 2',
      chinese: 'Chinese 2',
      price: 19.99,
    },
    // Add more articles as needed
  ]);

  useEffect(() => {
    console.log('Order component articles:', articles);
  }, [articles]);

  const handleDeleteArticle = (articleNumber: string) => {
    const updatedArticles = articles.filter((article) => article.articleNumber !== articleNumber);
    setArticles(updatedArticles);
  };

  const handleAmountChange = (articleId: string, amount: number) => {
    const updatedArticles = articles.map((article) => {
      if (article.articleNumber === articleId) {
        return { ...article, amount };
      }
      return article;
    });
    setArticles(updatedArticles);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(articles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setArticles(items);
  };

  const title = selectTitle(store.getState(), id);

  const changeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTitle({ orderId: id, title: event.target.value }));
  };

  const deleteOrderHandler = () => {
    dispatch(deleteOrder({ orderId: id }));
  };

  function loadArticlesFromFile() {
    return ArticleService.getArticles()
  }

  return (
      <div>
        <Grid container justifyContent="flex-start" spacing={2} alignItems="center">
          <Grid item xs={5} >
            <CwTextField label="Rezepturtitel" value={title} onChange={changeTitle} fullWidth />
          </Grid>
          <Grid item xs={7} container justifyContent="flex-end">
            <CwLightButton>Als Vorlage speichern</CwLightButton>
            &nbsp;
            <CwLightButton onClick={deleteOrderHandler}>Rezeptur löschen</CwLightButton>
          </Grid>
          <Grid item xs={5}>
            <div style={{ width: '100%' }}>
              <ArticleSearchComponent loadData={loadArticlesFromFile} onSelectArticle={article => console.log(article.ArticleName)} fullWidth/>
            </div>
          </Grid>
          <Grid item xs={7} container justifyContent="flex-end">
          </Grid>
          <Grid item xs={5} container justifyContent="flex-start">
            <CwLightButton>Katalog</CwLightButton>
            &nbsp;
            <CwLightButton>Herbmaster</CwLightButton>
            &nbsp;
            <CwLightButton>Compendium</CwLightButton>
          </Grid>
          <Grid item xs={7} container justifyContent="flex-end">
          </Grid>
          <Grid item xs={12}>
            <OrderItemGrid
                articles={articles}
                onDeleteArticle={handleDeleteArticle}
                onAmountChange={handleAmountChange}
                onDragEnd={handleDragEnd}
            />
          </Grid>
          <Grid item xs={12}>
            <Dosage orderId={id} />
          </Grid>
          <Grid item xs={12}>
            <ApplicationForm orderId={id} />
          </Grid>
          <Grid item xs={12}>
            <DosageCalculator orderId={id} />
          </Grid>
          <Grid item xs={12}>
            <OrderCosts orderId={id} />
          </Grid>
        </Grid>
      </div>
  );
};

export default Order;
