import React, { useState } from 'react';
import './App.css';
import Navigation from './views/Navigation';
import ButtonsPlayground from './views/ButtonsPlayground';
import SearchComponentPlayground from './views/SearchComponentPlayground';
import './css/components.css';
import EditPlayground from "./views/EditPlayground";
import TablesPlayground from "./views/TablesPlayground";
import DiagramPlayground from "./views/DiagramPlayground";
import PatientEditor from "patienteditor/src/views/PatientEditor";
import OrderPlayground from "./views/OrderPlayground";
import DialogPlayground from "./views/DialogPlayground";
import ReorderPlayground from "./views/ReorderPlayground";
import WidgetsPlayground from "./views/WidgetsPlayground";
import CwButton from 'shared-components/src/ui/CwButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const App: React.FC = () => {
    const [selectedComponent, setSelectedComponent] = useState<string>('buttons');
    const [isNavVisible, setIsNavVisible] = useState<boolean>(true);

    const renderComponent = () => {
        switch (selectedComponent) {
            case 'buttons':
                return <ButtonsPlayground />;
            case 'edit':
                return <EditPlayground />;
            case 'search':
                return <SearchComponentPlayground />;
            case 'tables':
                return <TablesPlayground />;
            case 'diagram':
                return <DiagramPlayground />;
            case 'dialogs':
                return <DialogPlayground />;
            case 'widgets':
                return <WidgetsPlayground />;
            case 'patientEditor':
                return <PatientEditor />;
            case 'order':
                return <OrderPlayground />;
            case 'reorder':
                return <ReorderPlayground />;
            case 'about':
                return <div><h3>About</h3><p>This is the about section.</p></div>;
            default:
                return <ButtonsPlayground />;
        }
    }

    const toggleNavigation = () => {
        setIsNavVisible(!isNavVisible);
    }

    return (
        <div className="App">
            {isNavVisible ? (
                <Navigation onSelect={setSelectedComponent} onToggle={toggleNavigation} />
            ) : (
                <CwButton
                    className="nav-toggle-button"
                    onClick={toggleNavigation}
                    style={{
                        position: 'fixed',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1000
                    }}
                >
                    <KeyboardDoubleArrowRightIcon />
                </CwButton>
            )}
            <div className={`content ${isNavVisible ? 'with-nav' : 'full-width'}`}>
                {renderComponent()}
            </div>
        </div>
    );
}

export default App;
