import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CwButton from 'shared-components/src/ui/CwButton';
import CwPrimaryButton from 'shared-components/src/ui/CwPrimaryButton';
import CwNumericField from 'shared-components/src/ui/CwNumericField';
import CwTabs from 'shared-components/src/ui/CwTabs';
import CwTab from 'shared-components/src/ui/CwTab';

interface CalculatorOptionsProps {
    onTitleUpdate: (title: string) => void;
}

const CalculatorOptions: React.FC<CalculatorOptionsProps> = ({ onTitleUpdate }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const buildTitle = () => {
        let title = '';

        switch (activeTab) {
            case 0:
                const portionDose = (document.getElementById('inputPortionDose') as HTMLInputElement)?.value;
                const portionTimes = (document.getElementById('inputPortionTimes') as HTMLInputElement)?.value;
                const portionDays = (document.getElementById('inputPortionDays') as HTMLInputElement)?.value;
                title = `Einzeldosis: ${portionDose}, Einnahmen pro Tag: ${portionTimes}, Tage: ${portionDays}`;
                break;
            case 1:
                const days = (document.getElementById('inputDays') as HTMLInputElement)?.value;
                const daysRequested = (document.getElementById('inputDaysRequested') as HTMLInputElement)?.value;
                title = `Eingabe entspricht: ${days} Tagen, Gewünschte Tage: ${daysRequested}`;
                break;
            case 2:
                const fullDose = (document.getElementById('inputFullDose') as HTMLInputElement)?.value;
                title = `Gesamtdosis: ${fullDose}`;
                break;
            default:
                title = '';
        }

        return title;
    };

    const applyCalculation = () => {
        const newTitle = buildTitle();
        onTitleUpdate(newTitle);
    };

    const resetCalculation = () => {
        // Clear input fields
        (document.getElementById('inputPortionDose') as HTMLInputElement).value = '';
        (document.getElementById('inputPortionTimes') as HTMLInputElement).value = '';
        (document.getElementById('inputPortionDays') as HTMLInputElement).value = '';
        (document.getElementById('inputDays') as HTMLInputElement).value = '';
        (document.getElementById('inputDaysRequested') as HTMLInputElement).value = '';
        (document.getElementById('inputFullDose') as HTMLInputElement).value = '';

        // Select the default tab (tab 0)
        setActiveTab(0);

        // Update the title in the parent component
        onTitleUpdate('');    };

    return (
        <Box id="calculatorOptions">
            <Box className="pricearea">
                <Box id="calculatortabstrip" className="pricearea">
                    <CwTabs value={activeTab} onChange={handleTabChange}>
                        <CwTab value={0} label={t('Nach Einzeldosis')} />
                        <CwTab value={1} label={t('Tagesdosis')} />
                        <CwTab value={2} label={t('Gesamtmenge')} />
                    </CwTabs>
                    <Box hidden={activeTab !== 0} mt={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    <b>{t('Hochrechnen der Mengen auf Basis von Portionen')}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CwNumericField
                                    id="inputPortionDose"
                                    label={t('Einzeldosierung')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CwNumericField
                                    id="inputPortionTimes"
                                    label={t('Anzahl Einnahmen pro Tag')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CwNumericField
                                    id="inputPortionDays"
                                    label={t('Anzahl Tage')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} id="basedOnPortions" />
                    </Box>
                    <Box hidden={activeTab !== 1} mt={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    <b>{t('Hochrechnen einer Dosis auf Basis einer Tagesdosis')}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CwNumericField
                                    id="inputDays"
                                    label={t('Eingabe entspricht')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CwNumericField
                                    id="inputDaysRequested"
                                    label={t('Gewünschte Anzahl Tage')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} id="basedOnDays" />
                    </Box>
                    <Box hidden={activeTab !== 2} mt={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    <b>{t('Hochrechnen der Mengen auf Basis einer Gesamtmenge')}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CwNumericField
                                    id="inputFullDose"
                                    label={t('Gesamtdosis')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} id="basedOnFullAmountDays" />
                    </Box>
                </Box>
                <Box className="pricearea calculatorarea" mt={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="applyCalculatorButtonArea">
                            <CwPrimaryButton
                                variant="contained"
                                color="primary"
                                onClick={applyCalculation}
                                id="applyCalculation"
                                className="rightSpace10"
                            >
                                {t('Anwenden')}
                            </CwPrimaryButton>&nbsp;
                            <CwButton onClick={resetCalculation}>
                                {t('Zurücksetzen')}
                            </CwButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default CalculatorOptions;
