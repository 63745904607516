import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../app/store';
import {
  IComplewebOrder, IComplewebOrderItem, IDelivery, IPatient, IPatientWithNotes,
} from '../interfaces/ComplewebInterfaces';
import { ApplicationForm, CalculatorMode, OrderType } from '../interfaces/Enums';

interface UpdateArticleAmountState {
  orderId: string,
  articleNumber: string;
  newAmount: number;
}

interface DeleteArticleState {
  orderId: string,
  articleNumber: string;
}

interface UpdateTitleState {
  orderId: string,
  title: string
}

interface UpdateOrderCustomerState {
  orderId: string,
  customer: IPatient
}

interface AddOrderItemState {
  orderId: string,
  item: IComplewebOrderItem
}

interface DeleteOrderState {
  orderId: string,
}

// Define the initial state using that type
const initialState: IDelivery = {
  id: uuidv4(),
  companyId: uuidv4(),
  orders: [],
  deliveryAddress: null,
  customer: null,
};

const round = (price: number, amount: number): number => {
  const total = ((price / 100) * amount);

  const result = (Math.round(total / 0.05) * 0.05);
  return parseFloat(result.toFixed(2));
};

export const orderSlice = createSlice({
  name: 'order',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDeliveryPersistenceState: (state, action: PayloadAction<string>) => {

    },
    setCustomer: (state, action: PayloadAction<IPatientWithNotes>) => {
      state.customer = action.payload;
      state.orders.forEach((orderx) => {
        state.orders = state.orders.map(
          (order) => (order.id === orderx.id
            ? { ...order, customer: action.payload } : order),
        );
      });
    },
    setTitle: (state, action: PayloadAction<UpdateTitleState>) => {
      state.orders = state.orders.map(
        (order) => (order.id === action.payload.orderId
          ? { ...order, title: action.payload.title } : order),
      );
    },
    loadOrder: (state, action: PayloadAction<IComplewebOrder>) => {
      state.orders = [...state.orders, action.payload];
    },
    addItem: (state, action: PayloadAction<AddOrderItemState>) => {
      state.orders = state.orders.map(
        (order) => (order.id === action.payload.orderId ? {
          ...order,
          items: [...order.items, action.payload.item],
        } : order),
      );
    },
    addOrder: (state, action: PayloadAction<OrderType>) => {
      console.log('created order');
      state.orders = [...state.orders,
        {
          id: uuidv4(),
          deliveryId: null,
          items: [],
          title: '',
          customer: state.customer ?? null,
          price: 0,
          total: 0,
          orderType: action.payload,
          companyId: uuidv4(),
          dosageCalculator: {
            mode: CalculatorMode.Daily, param1: null, param2: null, param3: null,
          },
          applicationForm: (action.payload === OrderType.Granulat) ? ApplicationForm.Granule : null,
        },
      ];
    },
    deleteOrder: (state, action: PayloadAction<DeleteOrderState>) => {
      state.orders = state.orders.filter((value) => value.id !== action.payload.orderId);
    },
    deleteArticle: (state, action: PayloadAction<DeleteArticleState>) => {
      const order = state.orders.find((o) => o.id === action.payload.orderId);
      if (order != null) {
        order.items = order.items.filter((value) => value.articleNumber !== action.payload.articleNumber);
      }
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAmount: (state, action: PayloadAction<UpdateArticleAmountState>) => {
      const order = state.orders.find((o) => o.id == action.payload.orderId);
      if (order != null && typeof order !== 'undefined') {
        order.items = order.items.map(
          (item) => (item.articleNumber === action.payload.articleNumber ? {
            ...item,
            amount: action.payload.newAmount,
            price: round(item.pricePer100, action.payload.newAmount),
          } : item),
        );

        let newVal = 0;
        let newPrice = 0;
        order.items.forEach((item) => {
          newVal += item?.amount ?? 0;
          newPrice += item.price;
        });
        order.total = newVal;
        order.price = newPrice;

        state.orders = state.orders.map(
          (currentorder) => (currentorder.id === action.payload.orderId
            ? order
            : currentorder),
        );
      }
    },
  },
});

export const {
  setDeliveryPersistenceState,
  setTitle,
  addItem,
  setAmount,
  addOrder,
  deleteOrder,
  setCustomer,
  loadOrder,
  deleteArticle,
} = orderSlice.actions;

export const selectTitle = (state: RootState, orderId: string) => state.delivery.orders.filter((o) => o.id === orderId)[0].title;

export const selectItems = (state: RootState, orderId: string) => state.delivery.orders.filter((o) => o.id === orderId)[0].items;

export const getTotalInputAmount = (state: RootState, orderId: string) => {
  const order = state.delivery.orders.find((o) => o.id === orderId);
  if (order != null && typeof order !== 'undefined') {
    return order.total;
  }
};

export const getTotalPrice = (state: RootState, orderId: string) => {
  const order = state.delivery.orders.find((o) => o.id === orderId);
  if (order != null && typeof order !== 'undefined') {
    return order.price;
  }
};

export const getDosageCalculator = (state: RootState, orderId: string) => {
  const order = state.delivery.orders.find((o) => o.id === orderId);
  if (order != null && typeof order !== 'undefined') {
    return order.dosageCalculator;
  }
};

export const getApplicationForm = (state: RootState, orderId: string) => {
  const order = state.delivery.orders.find((o) => o.id === orderId);
  if (order != null && typeof order !== 'undefined') {
    return order.applicationForm;
  }
};

export const getCustomer = (state: RootState) => state.delivery.customer;

export default orderSlice.reducer;
