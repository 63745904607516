import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArticleSearchComponent from 'shared-components/src/ui/ArticleSearchComponent';
import PatientSearchComponent from 'shared-components/src/ui/PatientSearchComponent';
import CwSimpleSearchBar from 'shared-components/src/ui/CwSimpleSearchBar';

import { ArticleService } from "../services/ArticleService";
import { PatientService } from "../services/PatientService";

const SearchComponentPlayground: React.FC = () => {
    function loadArticlesFromFile() {
        return ArticleService.getArticles()
    }

    function loadPatientsFromFile() {
        return PatientService.getPatients()
    }

    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>Search Articles</h3>
                        <ArticleSearchComponent loadData={loadArticlesFromFile} onSelectArticle={article => alert(article.ArticleName)}/>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>Search Patients</h3>
                        <PatientSearchComponent loadData={loadPatientsFromFile} onSelectPatient={patient => alert(patient.name)}/>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>SimpleSearch</h3>
                        <CwSimpleSearchBar onSearch={alert} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SearchComponentPlayground;
