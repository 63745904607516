import React, { useState } from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import AddIcon from '@mui/icons-material/Add';
import Widget from './Widget';

interface Item {
    id: string;
    title: string;
    content: JSX.Element;
    column: number;
}

interface WidgetAreaProps {
    initialItems: Item[];
    columns: number;
}

const WidgetArea: React.FC<WidgetAreaProps> = ({ initialItems, columns }) => {
    const [items, setItems] = useState<Item[]>(initialItems);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const { source, destination } = result;

        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        }

        const sourceColumn = parseInt(source.droppableId.split('-')[1], 10);
        const destinationColumn = parseInt(destination.droppableId.split('-')[1], 10);

        const sourceItems = items.filter(item => item.column === sourceColumn);
        const destinationItems = items.filter(item => item.column === destinationColumn);

        const [movedItem] = sourceItems.splice(source.index, 1);

        if (sourceColumn === destinationColumn) {
            sourceItems.splice(destination.index, 0, movedItem);
            setItems([
                ...items.filter(item => item.column !== sourceColumn),
                ...sourceItems,
            ]);
        } else {
            destinationItems.splice(destination.index, 0, movedItem);
            movedItem.column = destinationColumn;
            setItems([
                ...items.filter(item => item.column !== sourceColumn && item.column !== destinationColumn),
                ...sourceItems,
                ...destinationItems,
            ]);
        }
    };

    const handleAddClick = (columnIndex: number) => {
        alert(`Add button clicked in column ${columnIndex}`);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={3} style={{ height: '100vh' }}>
                {Array.from({ length: columns }, (_, columnIndex) => (
                    <Grid item xs={12 / columns} key={columnIndex} style={{ height: '100%' }}>
                        <Droppable droppableId={`column-${columnIndex}`} type="item">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{
                                        height: '100%',
                                        border: snapshot.isDraggingOver ? '2px dashed lightblue' : '2px solid lightgrey',
                                        padding: '8px',
                                        backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'white',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'relative'
                                    }}
                                >
                                    {items
                                        .filter(item => item.column === columnIndex)
                                        .map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided) => (
                                                    <Box mb={2} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <Widget
                                                            id={item.id}
                                                            title={item.title}
                                                            content={item.content}
                                                        />
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            opacity: 0,
                                            transition: 'opacity 0.3s',
                                            position: 'relative',
                                            width: '100%',
                                            height: '50px',
                                            cursor: 'pointer'
                                        }}
                                        onMouseEnter={(e) => (e.currentTarget.style.opacity = '1')}
                                        onMouseLeave={(e) => (e.currentTarget.style.opacity = '0')}
                                        onClick={() => handleAddClick(columnIndex)}
                                    >
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </Grid>
                ))}
            </Grid>
        </DragDropContext>
    );
};

export default WidgetArea;
