import React, { useState } from 'react';
import { Box, Typography, Divider, IconButton, Collapse } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { Patient } from 'shared-components/src/models/Patient';

interface PatientAreaProps {
    patient: Patient;
}

const PatientArea: React.FC<PatientAreaProps> = ({ patient }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Box sx={{ marginBottom: '1em', paddingX: '1em' }}>
            <Box onClick={toggleExpanded} sx={{ cursor: 'pointer' }}>
                <Typography variant="h6" component="div" align="left">
                    {patient.name} {patient.preName}, {patient.zip} {patient.city}
                </Typography>
                <Divider sx={{ marginTop: '0.5em' }} />
            </Box>
            <Collapse in={expanded}>
                <Box sx={{ marginTop: '1em' }}>
                    <Typography variant="body1" component="div" align="left">
                        {patient.name} {patient.preName}
                    </Typography>
                    <Typography variant="body1" component="div" align="left">
                        {patient.street}
                    </Typography>
                    <Typography variant="body1" component="div" align="left">
                        {patient.zip} {patient.city}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5em' }}>
                        <IconButton color="primary" component="a" href={`tel:${patient.phoneNumber}`}>
                            <PhoneIcon />
                        </IconButton>
                        <Typography variant="body1" component="div" align="left">
                            {patient.phoneNumber}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5em' }}>
                        <IconButton color="primary" component="a" href={`mailto:${patient.emailAddress}`}>
                            <EmailIcon />
                        </IconButton>
                        <Typography variant="body1" component="div" align="left">
                            {patient.emailAddress}
                        </Typography>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

export default PatientArea;
