import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormLabel from '@mui/material/FormLabel/FormLabel';
import UnderConstruction from 'shared-components/src/ui/UnderConstruction';

interface DosageProps {
  orderId: string;
}

const Dosage: React.VFC<DosageProps> = ({ orderId }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="applicationFormHeader"
    >
      <Typography component="span">Dosierung</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container>
        <Grid xs={12} style={{ textAlign: 'left' }}>
          <FormControl>
            <FormLabel>Eingabe entspricht:</FormLabel>
          </FormControl>
        </Grid>
      </Grid>
      <UnderConstruction />
    </AccordionDetails>
  </Accordion>
);

export default Dosage;
