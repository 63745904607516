import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CwButton from '../CwButton';
import CwPrimaryButton from '../CwPrimaryButton';


interface CwSaveDialogProps {
    open: boolean;
    title: string;
    content: React.ReactNode;
    onSave: () => void;
    onCancel: () => void;
    width?: string | number;
    height?: string | number;
}

const CwSaveDialog: React.FC<CwSaveDialogProps> = ({ open, title, content, onSave, onCancel, width, height }) => {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="save-dialog-title"
            maxWidth={false}
            PaperProps={{
                style: {
                    width: width || 'auto',
                    height: height || 'auto',
                    minWidth: width ? undefined : '300px',
                    minHeight: height ? undefined : '150px',
                }
            }}
        >
            <DialogTitle id="save-dialog-title">
                {title}
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: theme.spacing(1),
                        color: theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {content}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-start' }}>
                <CwPrimaryButton onClick={onSave} color="primary" variant="contained">
                    Save
                </CwPrimaryButton>
                <CwButton onClick={onCancel} color="secondary" variant="contained" sx={{ ml: 1 }}>
                    Cancel
                </CwButton>
            </DialogActions>
        </Dialog>
    );
};

export default CwSaveDialog;
