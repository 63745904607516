import React, { useState } from "react";
import CwTextField from "shared-components/src/ui/CwTextField";
import CwButton from "shared-components/src/ui/CwButton";
import { useTranslation } from 'react-i18next';
import {
    Box
} from '@mui/material';
import CwEmailTextField from "shared-components/src/ui/CwEmailTextField";
import CwPhonenumberTextField from "shared-components/src/ui/CwPhonenumberTextField";
import CwBirthdayPicker from "shared-components/src/ui/CwBirthdayPicker";
import {Dayjs} from "dayjs";

const PatientEditor: React.FC = () => {
    const { t } = useTranslation('PatientEditor'); // Specify the namespace
    const [birthday, setBirthday] = useState<Dayjs | null>(null);
    const handleDateChange = (date: Dayjs | null) => {
        setBirthday(date);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '400px',
                margin: '0 auto',
                marginTop: '50px',
            }}
        >
            <CwTextField label={t('name')} required />
            <CwTextField label={t('prename')} required />
            <CwTextField label={t('coAddress')} />
            <CwTextField label={t('street')} required />
            <CwTextField label={t('zip')} required />
            <CwTextField label={t('city')} required />
            <CwBirthdayPicker label={t('birthday')} onDateChange={handleDateChange} />
            <CwPhonenumberTextField label={t('phoneNumber')} />
            <CwEmailTextField label={t('email')} />

            <CwButton variant="contained" color="primary">
                {t('save')}
            </CwButton>
        </Box>
    );
}

export default PatientEditor;
