import React from 'react';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(1),
    },
    '& .MuiRadio-root': {
        color: theme.palette.primary.main,
    },
    '& .Mui-checked': {
        color: theme.palette.secondary.main,
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: theme.typography.body1.fontSize,
    },
}));

interface CwRadioGroupProps extends Omit<RadioGroupProps, 'onChange'> {
    options: { value: string; label: string }[];
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
}

const CwRadioGroup: React.FC<CwRadioGroupProps> = ({ options, name, onChange, label, ...props }) => {
    const { t } = useTranslation('UIComponents'); // Specify the namespace

    return (
        <Box p={2} pt={3} pb={2} border={1} borderRadius={1} borderColor="rgba(0, 0, 0, 0.23)" position="relative">
            <Typography
                variant="caption"
                style={{
                    position: 'absolute',
                    top: -10,
                    left: 10,
                    backgroundColor: 'white',
                    padding: '0 4px',
                    color: 'rgba(0, 0, 0, 0.6)', // Match the text color of the outlined label
                }}
            >
                {t(label)}
            </Typography>
            <StyledRadioGroup name={name} onChange={onChange} {...props}>
                {options.map((option) => (
                    <StyledFormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={t(option.label)}
                        name={name}
                    />
                ))}
            </StyledRadioGroup>
        </Box>
    );
};

export default CwRadioGroup;
