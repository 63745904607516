import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    IconButton,
    InputAdornment,
    Popover,
    List,
    ListItem,
    ListItemText,
    Checkbox,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash';
import { FixedSizeList as VirtualList } from 'react-window';
import { Article } from '../models/Article';

import '../css/components.css';

interface ArticleSearchComponentProps {
    loadData: () => Promise<Article[]>;
    onSelectArticle: (article: Article) => void;
    fullWidth?: boolean;
}

interface RowProps {
    index: number;
    style: React.CSSProperties;
}

const ArticleSearchComponent: React.FC<ArticleSearchComponentProps> = ({ loadData, onSelectArticle, fullWidth }) => {
    const [searchText, setSearchText] = useState('');
    const [articles, setArticles] = useState<Article[]>([]);
    const [filteredArticles, setFilteredArticles] = useState<Article[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [enterPressed, setEnterPressed] = useState(false);
    const [sortField, setSortField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isFiltering, setIsFiltering] = useState(false);

    const initialColumnConfig = useMemo(
        () => [
            { fieldName: 'ArticleNumber', title: 'Artikelnummer', width: '20%', visible: true, sortable: true },
            { fieldName: 'ArticleName', title: 'Pharmaname', width: '30%', visible: true, sortable: true },
            { fieldName: 'PinYin', title: 'Pin Yin', width: '30%', visible: true, sortable: true },
            { fieldName: 'Group', title: 'Gruppe', width: '20%', visible: true, sortable: true },
        ],
        []
    );

    const [columnConfig, setColumnConfig] = useState(initialColumnConfig);

    useEffect(() => {
        const init = async () => {
            try {
                const loadedArticles = await loadData();
                setArticles(loadedArticles);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        init();
    }, [loadData]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setSearchText(text);
        setIsFiltering(true);

        const filtered = articles.filter((article) => {
            return columnConfig.some((column) => {
                if (!column.visible) return false;
                const fieldValue = String(article[column.fieldName as keyof Article] || '');
                return fieldValue.toLowerCase().includes(text.toLowerCase());
            });
        });

        setFilteredArticles(filtered);
        setIsFiltering(false);
    };

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            switch (event.key) {
                case 'ArrowDown':
                    setSelectedIndex((prevIndex) => {
                        if (prevIndex === null || prevIndex === filteredArticles.length - 1) {
                            return 0;
                        } else {
                            return prevIndex + 1;
                        }
                    });
                    break;
                case 'ArrowUp':
                    setSelectedIndex((prevIndex) => {
                        if (prevIndex === null || prevIndex === 0) {
                            return filteredArticles.length - 1;
                        } else {
                            return prevIndex - 1;
                        }
                    });
                    break;
                case 'Enter':
                    setEnterPressed(true);
                    break;
                default:
                    break;
            }
        },
        [filteredArticles.length]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (enterPressed && selectedIndex !== null) {
            onSelectArticle(filteredArticles[selectedIndex]);
            setEnterPressed(false);
            setSearchText('');
            setFilteredArticles([]);
        }
    }, [enterPressed, selectedIndex, filteredArticles, onSelectArticle]);

    const highlightText = useCallback((text: string) => {
        if (!searchText) return text;
        const regex = new RegExp(`(${_.escapeRegExp(searchText)})`, 'gi');
        return text.split(regex).map((part, index) =>
            regex.test(part) ? (
                <span key={index} style={{ backgroundColor: '#FFD700' }}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    }, [searchText]);

    const handleSort = (field: string) => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortField(field);
    };

    const sortArray = useMemo(() => {
        return (array: Article[]) => {
            return array.sort((a, b) => {
                if (!sortField) return 0;

                const aValue = a[sortField as keyof Article];
                const bValue = b[sortField as keyof Article];

                if (aValue < bValue) {
                    return sortDirection === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
            });
        };
    }, [sortField, sortDirection]);

    const handleConfigClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleVisibilityChange = (index: number) => {
        const newConfig = [...columnConfig];
        newConfig[index].visible = !newConfig[index].visible;
        setColumnConfig(newConfig);
    };

    const maxRowHeight = 58;
    const headerHeight = 52;
    const maxTableHeight = Math.min(filteredArticles.length, 10) * maxRowHeight + headerHeight;

    const visibleColumns = useMemo(() => columnConfig.filter((col) => col.visible), [columnConfig]);

    const handleRowClick = (index: number) => {
        setSelectedIndex(index);
        setEnterPressed(true);
    };

    const Row = useCallback(
        ({ index, style }: RowProps) => {
            const article = sortArray(filteredArticles)[index];
            return (
                <TableRow
                    style={{
                        ...style,
                        display: 'flex',
                        backgroundColor: selectedIndex === index ? 'lightgrey' : 'white',
                    }}
                    onClick={() => handleRowClick(index)}
                >
                    {visibleColumns.map((col) => (
                        <TableCell
                            key={col.fieldName}
                            style={{ flex: `0 0 ${col.width}`, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                            {highlightText(String(article[col.fieldName as keyof Article]))}
                        </TableCell>
                    ))}
                </TableRow>
            );
        },
        [filteredArticles, selectedIndex, visibleColumns, sortArray, handleRowClick, highlightText]
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    {columnConfig.map((column, index) => (
                        <ListItem key={column.fieldName}>
                            <Checkbox
                                checked={column.visible}
                                onChange={() => handleVisibilityChange(index)}
                            />
                            <ListItemText primary={column.title} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
            <div style={{ width: fullWidth ? '100%' : '40%', textAlign: 'left' }}>
                <TextField
                    label="Artikelsuche"
                    variant="outlined"
                    fullWidth={fullWidth}
                    value={searchText}
                    onChange={handleSearch}
                    autoComplete="off"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {isFiltering && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
                                {searchText && (
                                    <IconButton
                                        onClick={() => setSearchText('')}
                                        style={{ marginRight: isFiltering ? 0 : 15 }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <IconButton onClick={handleConfigClick}>
                                    <SettingsIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            {filteredArticles.length > 0 && searchText.trim() !== '' && (
                <TableContainer
                    component={Paper}
                    style={{
                        zIndex: 1000,
                        width: fullWidth ? '100%' : '90%',
                        maxHeight: `${maxTableHeight}px`,
                        border: '1px solid #FFD700',
                        borderRadius: '5px',
                        overflow: 'auto',
                        paddingRight: '20px', // Adjust padding to leave space for the scrollbar
                        position: 'relative' // Make sure the container is positioned relative
                    }}
                >
                    <Table stickyHeader style={{ width: 'calc(100% - 20px)' }}>
                        <TableHead>
                            <TableRow style={{ display: 'flex', width: '100%' }}>
                                {visibleColumns.map((col) => (
                                    <TableCell
                                        key={col.fieldName}
                                        style={{
                                            flex: `0 0 ${col.width}`,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: '#fff',
                                            zIndex: 1,
                                        }}
                                    >
                                        {col.sortable ? (
                                            <TableSortLabel
                                                active={sortField === col.fieldName}
                                                direction={sortField === col.fieldName ? sortDirection : 'asc'}
                                                onClick={() => handleSort(col.fieldName)}
                                            >
                                                {col.title}
                                            </TableSortLabel>
                                        ) : (
                                            col.title
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ display: 'block', width: '100%' }}>
                            <VirtualList
                                height={maxTableHeight - headerHeight}
                                itemCount={filteredArticles.length}
                                itemSize={maxRowHeight}
                                width="100%"
                                style={{ overflow: 'hidden' }}
                            >
                                {Row}
                            </VirtualList>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default ArticleSearchComponent;
