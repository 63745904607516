import React, { ChangeEvent } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Grid, Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormLabel from '@mui/material/FormLabel/FormLabel';
import { store } from 'shared-components/src/app/store';
import { getApplicationForm } from 'shared-components/src/features/orderSlice';
import UnderConstruction from 'shared-components/src/ui/UnderConstruction';

interface ApplicationFormProps {
  orderId: string;
}

const ApplicationForm: React.VFC<ApplicationFormProps> = ({ orderId }) => {
  const applicationForm = getApplicationForm(store.getState(), orderId);

  const changeApplicationForm = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    alert(e.target.value);
  };

  if (applicationForm != null) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="applicationFormHeader"
        >
          <Typography component="span">Anwendungsform</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid xs={12} style={{ textAlign: 'left' }}>
              <FormControl>
                <RadioGroup
                  row
                  onChange={changeApplicationForm}
                  value={applicationForm}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Granulat" />
                  <FormControlLabel value="1" control={<Radio />} label="Tabletten" />
                  <FormControlLabel value="2" control={<Radio />} label="Kapseln" />
                </RadioGroup>
              </FormControl>
              <UnderConstruction />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
  return <></>;
};

export default ApplicationForm;
