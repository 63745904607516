import React from "react";
import {List, ListItem, ListItemText} from "@mui/material";

const LastOrders: React.FC = () => {
    const lastOrders = [
        { id: 1, name: 'Order 1', date: '2024-06-10' },
        { id: 2, name: 'Order 2', date: '2024-06-11' },
        { id: 3, name: 'Order 3', date: '2024-06-12' },
    ];

    return (
        <List>
            {lastOrders.map(order => (
                <ListItem key={order.id}>
                    <ListItemText primary={order.name} secondary={order.date} />
                </ListItem>
            ))}
        </List>
    );
};

export default LastOrders;
