export enum OrderType {
  Granulat,
  Rohdrogen,
  Tabletten,
}

export enum CalculatorMode {
  All = 0,
  Daily = 1,
  Consumption = 2,
}

export enum ApplicationForm {
  Granule = 0,
  Tablet = 1,
  Capsules = 2,
}
