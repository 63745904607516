import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

const CwTabs = styled(Tabs)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
});

export default CwTabs;
