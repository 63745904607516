import { Patient } from 'shared-components/src/models/Patient';
import patientDataJson from '../data/Patient.json';

const patientData: Patient[] = patientDataJson as Patient[];

export class PatientService {
    static async getPatients(): Promise<Patient[]> {
        try {
            return patientData;
        } catch (error) {
            console.error('Error fetching file:', error);
            throw error;
        }
    }
}
