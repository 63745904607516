import React from "react";
import {List, ListItem, ListItemText} from "@mui/material";

const OpeningHours: React.FC = () => {
    const openingHours = [
        'Monday: 9:00 AM - 5:00 PM',
        'Tuesday: 9:00 AM - 5:00 PM',
        'Wednesday: 9:00 AM - 5:00 PM',
        'Thursday: 9:00 AM - 5:00 PM',
        'Friday: 9:00 AM - 5:00 PM',
        'Saturday: 10:00 AM - 2:00 PM',
        'Sunday: Closed',
    ];

    return (
        <List>
            {openingHours.map((hour, index) => (
                <ListItem key={index}>
                    <ListItemText primary={hour} />
                </ListItem>
            ))}
        </List>
    );
};

export default OpeningHours;
