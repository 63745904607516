import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
        },
    },
}));

const CwTextField: React.FC<TextFieldProps> = (props) => {
    const [value, setValue] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <StyledTextField
            variant="outlined"
            InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
            {...props}
            value={value}
            onChange={handleChange}
            autoComplete={uuidv4()}
            error={props.required && value === ''}
        />
    );
};

export default CwTextField;
