import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormLabel from '@mui/material/FormLabel/FormLabel';
import { getTotalPrice } from 'shared-components/src/features/orderSlice';
import { store } from 'shared-components/src/app/store';
import UnderConstruction from 'shared-components/src/ui/UnderConstruction';
import CWOrderCosts from "./CwOrderCosts";

interface OrderCostProps {
  orderId: string;
}

const OrderCosts: React.VFC<OrderCostProps> = ({ orderId }) => {
  const totalPrice = getTotalPrice(store.getState(), orderId);
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="applicationFormHeader"
      >
        <Typography component="span">
          Kosten der Verschreibung - CHF
          {' '}
          {totalPrice}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid xs={12} style={{ textAlign: 'left' }}>
            <CWOrderCosts
                customerType={2}
                costs={{
                    totalArticlePrice: 100,
                    ebDiscount: 30,
                    nettoArticlePrice: 70,
                    workPrice: 10,
                    endpostPrice: 5,
                    pickupPrice: 2,
                    totalPriceNoTax: 87,
                    mwstPrice: 2.26,
                    totalPrice: 89.26,
                }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderCosts;
