import React, {useState} from 'react';
import OrderItemGrid from "order/src/views/OrderItemGrid";
import {DropResult} from "@hello-pangea/dnd";
import Box from "@mui/material/Box";

const TablesPlayground: React.FC = () => {
    const [articles, setArticles] = useState([
        {
            amount: 10,
            articleNumber: 'A001',
            articleName: 'Article 1',
            pinYin: 'Pin Yin 1',
            chinese: 'Chinese 1',
            price: 9.99,
        },
        {
            amount: 20,
            articleNumber: 'A002',
            articleName: 'Article 2',
            pinYin: 'Pin Yin 2',
            chinese: 'Chinese 2',
            price: 19.99,
        },
        // Add more articles as needed
    ]);

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(articles);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setArticles(items);
    };

    return (
        <div>
            <h3>Bestelltabelle</h3>
            <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                <OrderItemGrid articles={articles} onDeleteArticle={articleId => console.log(articleId)} onAmountChange={articleId => console.log(articleId)} onDragEnd={handleDragEnd} />
            </Box>
        </div>
    );
}

export default TablesPlayground;
