import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { useAppSelector } from 'shared-components/src/app/hooks';
import IconGranulat from '../images/icons/icon_granulate_dunkel.png';
import IconRohdrogen from '../images/icons/icon_rohdrogen_dunkel.png';
import IconTabletten from '../images/icons/icon_tabletten_dunkel.png';
import { OrderType } from 'shared-components/src/interfaces/Enums';

const OrderSummary: React.VFC = () => {
  const orders = useAppSelector((state) => state.delivery.orders);

  const getIcon = (orderType: OrderType) => {
    switch (orderType) {
      case OrderType.Granulat:
        return (<img alt="Granulat" height={25} src={IconGranulat} />);
        break;
      case OrderType.Rohdrogen:
        return (<img alt="Rohdrogen" height={25} src={IconRohdrogen} />);
        break;
      case OrderType.Tabletten:
        return (<img alt="Tabletten" height={25} src={IconTabletten} />);
        break;
      default:
        return (<></>);
    }
  };

  return (
    <>
      Zusammenfassung
      <br />
      {
                (orders.length > 0)
                  ? (
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" />
                            <TableCell align="left">Titel</TableCell>
                            <TableCell align="right">Menge</TableCell>
                            <TableCell align="right">Preis</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                                        orders.map((order) => (
                                          <TableRow>
                                            <TableCell align="left">{getIcon(order.orderType)}</TableCell>
                                            <TableCell align="left">{order.title}</TableCell>
                                            <TableCell align="right">{order.total}</TableCell>
                                            <TableCell align="right">{order.price}</TableCell>
                                          </TableRow>
                                        ))
                                    }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : <></>
            }
    </>
  );
};

export default OrderSummary;
