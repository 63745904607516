import React, { useState, useEffect } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
    },
}));

const CwEmailTextField: React.FC<TextFieldProps> = (props) => {
    const { t } = useTranslation('UIComponents'); // Specify the namespace
    const [value, setValue] = useState<string>('');
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (props.onChange) {
            props.onChange(event);
        }
    };

    useEffect(() => {
        if (value) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setIsValidEmail(emailPattern.test(value));
        } else {
            setIsValidEmail(true); // Assume valid when empty
        }
    }, [value]);

    const isError = !isValidEmail || (props.required && value === '');

    return (
        <StyledTextField
            variant="outlined"
            InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
            {...props}
            value={value}
            onChange={handleChange}
            autoComplete={uuidv4()}
            error={isError}
            helperText={isError ? t('enterValidEmail') : props.helperText}
        />
    );
};

export default CwEmailTextField;
