import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from '@emotion/styled';

interface WidgetProps {
    id: string;
    title: string;
    content: JSX.Element;
}

const Widget: React.FC<WidgetProps> = ({ id, title, content }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Card>
                <WidgetHeader>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    <IconButton onClick={handleSettingsClick}>
                        <SettingsIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Option 1</MenuItem>
                        <MenuItem onClick={handleClose}>Option 2</MenuItem>
                        <MenuItem onClick={handleClose}>Option 3</MenuItem>
                    </Menu>
                </WidgetHeader>
                <CardContent>{content}</CardContent>
            </Card>
        </Box>
    );
};

const WidgetHeader = styled(Box)`
  background-color: #f0f0f0;
  padding: 8px;
  cursor: grab;
  user-select: none;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    .MuiIconButton-root {
      visibility: visible;
    }
  }
  .MuiIconButton-root {
    visibility: hidden;
  }
`;

export default Widget;
