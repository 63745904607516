import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const blue = {
  500: '#f3f0e2',
  600: '#f3f0a2',
  700: '#f3f0e2',
};

const CwLightButton = styled(Button)(({ theme }) => ({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 'normal',
  fontSize: '0.875rem',
  backgroundColor: blue[500],
  padding: '12px 24px',
  borderRadius: '25px',
  color: 'black',
  transition: 'all 150ms ease',
  cursor: 'pointer',
  border: 'none',
  '&:hover': {
    backgroundColor: blue[600],
  },
  '&:active': {
    backgroundColor: blue[700],
  },
  '&.Mui-focusVisible': {
    boxShadow: '0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5)',
    outline: 'none',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

export default CwLightButton;
