import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Wrapper = styled('div')({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
});

const CustomButton = styled('button')({
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '20px',
});

const CustomButtonsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    height: '100%',
    top: 0,
    bottom: 0,
    justifyContent: 'center', // Vertically center the buttons
});

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        paddingRight: '30px', // Make room for the custom buttons
        '& input[type=number]': {
            '-moz-appearance': 'textfield', // Hide default spinner in Firefox
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none', // Hide default spinner in WebKit browsers
            margin: 0,
        },
    },
}));

interface CwNumericFieldProps extends Omit<TextFieldProps, 'type' | 'value' | 'onChange'> {
    min?: number;
    max?: number;
    step?: number;
    value?: number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CwNumericField: React.FC<CwNumericFieldProps> = (props) => {
    const { min, max, step = 1, value: initialValue, onChange, ...rest } = props;
    const [value, setValue] = useState<number | undefined>(initialValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value);

        if (isNaN(newValue)) {
            setValue(undefined);
        } else {
            let clampedValue = newValue;
            if (min !== undefined) {
                clampedValue = Math.max(clampedValue, min);
            }
            if (max !== undefined) {
                clampedValue = Math.min(clampedValue, max);
            }
            setValue(clampedValue);
        }

        if (onChange) {
            onChange(event);
        }
    };

    const handleIncrement = () => {
        setValue((prevValue) => {
            let newValue = (prevValue || 0) + step;
            if (max !== undefined) {
                newValue = Math.min(newValue, max);
            }
            if (onChange) {
                const event = { target: { value: newValue.toString() } } as React.ChangeEvent<HTMLInputElement>;
                onChange(event);
            }
            return newValue;
        });
    };

    const handleDecrement = () => {
        setValue((prevValue) => {
            let newValue = (prevValue || 0) - step;
            if (min !== undefined) {
                newValue = Math.max(newValue, min);
            }
            if (onChange) {
                const event = { target: { value: newValue.toString() } } as React.ChangeEvent<HTMLInputElement>;
                onChange(event);
            }
            return newValue;
        });
    };

    return (
        <Wrapper>
            <StyledTextField
                variant="outlined"
                InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
                {...rest}
                type="number"
                value={value !== undefined ? value : ''}
                onChange={handleChange}
                autoComplete={uuidv4()}
                error={props.required && value === undefined}
                inputProps={{
                    min: min !== undefined ? min : '',
                    max: max !== undefined ? max : '',
                    step: step !== undefined ? step : 'any',
                }}
            />
            <CustomButtonsContainer>
                <CustomButton onClick={handleIncrement}><ArrowDropUpIcon /></CustomButton>
                <CustomButton onClick={handleDecrement}><ArrowDropDownIcon /></CustomButton>
            </CustomButtonsContainer>
        </Wrapper>
    );
};

export default CwNumericField;
