import React, { useState } from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de-ch';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

dayjs.locale('de-ch');

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
    },
}));

interface CwBirthdayPickerProps extends Omit<DatePickerProps<Dayjs>, 'slots' | 'onChange'> {
    onDateChange?: (date: Dayjs | null) => void;
    label: string;
    value?: Dayjs | null;
}

const CwBirthdayPicker: React.FC<CwBirthdayPickerProps> = ({ onDateChange, label, value, ...props }) => {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(value || null);

    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date);
        if (onDateChange) {
            onDateChange(date);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de-ch">
            <DatePicker
                {...props}
                label={t(label)}
                value={selectedDate}
                onChange={handleDateChange}
                slots={{
                    textField: (params: TextFieldProps) => (
                        <StyledTextField
                            {...params}
                            autoComplete={uuidv4()}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    ),
                }}
            />
        </LocalizationProvider>
    );
};

export default CwBirthdayPicker;
