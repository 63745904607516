import React, { useState, useEffect } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
    },
}));

const CwPhonenumberTextField: React.FC<TextFieldProps> = (props) => {
    const { t } = useTranslation('UIComponents'); // Specify the namespace
    const [value, setValue] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const formatter = new AsYouType('CH');
        const formattedInput = formatter.input(inputValue);

        setValue(formattedInput);
        setIsValid(true); // Assume valid while editing
        if (props.onChange) {
            props.onChange(event);
        }
    };

    const handleBlur = () => {
        try {
            const phoneNumber = parsePhoneNumber(value, 'CH');
            if (phoneNumber.isValid()) {
                setValue(phoneNumber.formatInternational());
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } catch (error) {
            setIsValid(false);
        }
    };

    useEffect(() => {
        if (value !== '') {
            try {
                const phoneNumber = parsePhoneNumber(value, 'CH');
                setIsValid(phoneNumber.isValid());
            } catch (error) {
                setIsValid(false);
            }
        } else if (props.required) {
            setIsValid(false); // Mark as invalid if required and empty
        } else {
            setIsValid(true); // Otherwise mark as valid
        }
    }, [value, props.required]);

    const isError = !isValid || (props.required && value === '');

    return (
        <StyledTextField
            variant="outlined"
            InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
            {...props}
            value={value}
            onChange={handleChange}
            autoComplete={uuidv4()}
            onBlur={handleBlur}
            error={isError}
            helperText={isError ? t('enterValidPhonenumber') : props.helperText}
        />
    );
};

export default CwPhonenumberTextField;
