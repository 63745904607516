import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Box,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CwButton from '../CwButton';

interface InfoDialogComponentProps {
    open: boolean;
    title: string;
    content: React.ReactNode;
    onClose: () => void;
    width?: string | number;
    height?: string | number;
}

const CwInfoDialog: React.FC<InfoDialogComponentProps> = ({ open, title, content, onClose, width, height }) => {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
    onClose={onClose}
    aria-labelledby="info-dialog-title"
    maxWidth={false}
    PaperProps={{
        style: {
            width: width || 'auto',
                height: height || 'auto',
                minWidth: width ? undefined : '300px',
                minHeight: height ? undefined : '150px',
        }
    }}
>
    <DialogTitle id="info-dialog-title">
        {title}
        <IconButton
    aria-label="close"
    onClick={onClose}
    sx={{
        position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
    }}
>
    <CloseIcon />
    </IconButton>
    </DialogTitle>
    <DialogContent dividers>
    {content}
    </DialogContent>
    <DialogActions sx={{ justifyContent: 'flex-start' }}>
    <CwButton onClick={onClose} color="primary" variant="contained">
        Close
        </CwButton>
        </DialogActions>
        </Dialog>
);
};

export default CwInfoDialog;
