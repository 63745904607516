import React, { FC, ReactNode } from 'react';
import { IconButton } from '@mui/material';
import '../css/components.css';

interface RoundIconButtonProps {
    onClick: () => void;
    icon: ReactNode;
}

const CWRoundIconButton: FC<RoundIconButtonProps> = ({ onClick, icon }) => {
    return (
        <IconButton
            onClick={onClick}
    color="primary"
    className="cw-round-icon-button"
    aria-label="search"
        >
        {icon}
        </IconButton>
);
};

export default CWRoundIconButton;
