import React, { useState, FC } from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CwRoundIconButton from "./CwRoundIconButton";

interface SearchBarProps {
    onSearch: (term: string) => void;
}

const CwSimpleSearchBar: FC<SearchBarProps> = ({ onSearch }) => {
    const [term, setTerm] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerm(event.target.value);
    };

    const handleSearchSubmit = () => {
        onSearch(term);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    return (
        <Box display="flex" alignItems="center">
            <TextField
                fullWidth={true}
                label="Suche"
                variant="outlined"
                value={term}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                style={{ marginRight: 8, width: '50%' }}
            />
            <CwRoundIconButton onClick={handleSearchSubmit} icon={<SearchIcon style={{ color: 'black' }} />} /> {/* Pass the search icon as a prop */}
        </Box>
    );
}

export default CwSimpleSearchBar;
