import React from 'react';
import UC from '../images/uc.jpeg';

function UnderConstruction() {
  return (
    <div>
      <img src={UC} alt="Noch nicht umgesetzt" width={100} />
    </div>
  );
}

export default UnderConstruction;
