import { Article } from 'shared-components/src/models/Article';
import articleDataJson from '../data/Article.json';
import {ArticleData} from "./ArticleData";

const articleData: ArticleData[] = articleDataJson as ArticleData[];

export class ArticleService {
    static async getArticles(): Promise<Article[]> {
        try {
            return articleData.map(item => new Article(item.ArticleNumber, item.ArticleName, item.Chinese, item.Group, item.PinYin, item.Price, item.Unit));
        } catch (error) {
            console.error('Error fetching file:', error);
            throw error;
        }
    }
}
