import React from 'react';
import WidgetArea from 'shared-components/src/ui/WidgetArea';
import LastOrders from "./widgets/LastOrders";
import OpeningHours from "./widgets/OpeningHours";
import OpenOrders from "./widgets/OpenOrders";
import AdditionalItem from "./widgets/AdditionalItem";

const WidgetsPlayground: React.FC = () => {
    const initialItems = [
        { id: '1', title: 'Last Orders', content: <LastOrders />, column: 0 },
        { id: '2', title: 'Opening Hours', content: <OpeningHours />, column: 1 },
        { id: '3', title: 'Open Orders', content: <OpenOrders />, column: 2 },
        { id: '4', title: 'Additional Item', content: <AdditionalItem />, column: 0 },
    ];

    return <WidgetArea initialItems={initialItems} columns={3} />;
}

export default WidgetsPlayground;
