import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CwRoundIconButton from 'shared-components/src/ui/CwRoundIconButton';
import CwButton from 'shared-components/src/ui/CwButton';
import CwPrimaryButton from 'shared-components/src/ui/CwPrimaryButton';
import SearchIcon from '@mui/icons-material/Search';

const ButtonsPlayground: React.FC = () => {
    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>RoundButton</h3>
                        <CwRoundIconButton icon={<SearchIcon />} onClick={() => console.log("Search clicked")} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwButton</h3>
                        <CwButton>Testbutton</CwButton>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwPrimaryButton</h3>
                        <CwPrimaryButton>Testbutton</CwPrimaryButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ButtonsPlayground;

