import React, {useState} from 'react';
import CwButton from 'shared-components/src/ui/CwButton';
import Box from "@mui/material/Box";
import CwInfoDialog from "shared-components/src/ui/dialogs/CwInfoDialog";
import CwSaveDialog from "shared-components/src/ui/dialogs/CwSaveDialog";


const DialogPlayground: React.FC = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);


    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleSaveOpenDialog = () => {
        setSaveDialogOpen(true);
    };

    const handleSave = () => {
        console.log('Save action');
        setSaveDialogOpen(false);
    };

    const handleSaveCancel = () => {
        console.log('Cancel action');
        setSaveDialogOpen(false);
    };

    return (
        <div>
            <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                <CwButton variant="contained" color="primary" onClick={handleOpenDialog}>
                    Open Info Dialog
                </CwButton>
                <CwInfoDialog
                    open={dialogOpen}
                    title="Information"
                    content={<div>Your content goes here</div>}
                    onClose={handleCloseDialog}
                    width="400px"
                    height="300px"
                />
            </Box>
            <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                <CwButton variant="contained" color="primary" onClick={handleSaveOpenDialog}>
                    Open Save Dialog
                </CwButton>
                <CwSaveDialog
                    open={saveDialogOpen}
                    title="Save confirmation"
                    content={<div>Do you really want to save?</div>}
                    onCancel={handleSaveCancel}
                    onSave={handleSave}
                    width="400px"
                    height="300px"
                />
            </Box>
        </div>
    );
}

export default DialogPlayground;
