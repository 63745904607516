// CwTab.tsx
import Tab, {TabOwnProps} from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import React from 'react';

const blue = {
  400: '#fdf8d6',
};

const tabColor = {
  selectedBackground: '#ffffff',
  selectedFontColor: '#a9933d',
};

interface CwTabProps extends Omit<TabOwnProps, 'icon'> {
  onClick?: React.MouseEventHandler<HTMLElement>;
  value: number;
  label: string;
  icon?: React.ReactNode;
}

const CwTab: React.FC<CwTabProps> = styled((props: CwTabProps) => {
  const {
    onClick, value, label, icon, ...rest
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
      <Tab
          {...rest}
          value={value}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {icon}
              {icon && <span style={{ marginLeft: '8px' }}>{label}</span>}
              {!icon && <span>{label}</span>}
            </div>
          }
          onClick={handleClick}
      />
  );
})(({ theme }) => ({
  fontFamily: 'IBM Plex Sans, sans-serif',
  color: 'black',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontWeight: 'normal',
  backgroundColor: 'white',
  padding: '8px 16px',
  margin: '6px 2px',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',

  '&:hover': {
    backgroundColor: blue[400],
  },

  '&.Mui-selected': {
    backgroundColor: tabColor.selectedBackground,
    fontWeight: 'bold',
    color: tabColor.selectedFontColor,
  },

  '&.Mui-disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

export default CwTab;
