export class Article {
    ArticleNumber: string;
    ArticleName: string;
    Chinese: string;
    Group: string;
    PinYin: string;
    Price: number;
    Unit: string;

    constructor(articleNumber: string, articleName: string, chinese: string, group: string, pinYin: string, price: number, unit: string) {
        this.ArticleNumber = articleNumber;
        this.ArticleName = articleName;
        this.Chinese = chinese;
        this.Group = group;
        this.PinYin = pinYin;
        this.Price = price;
        this.Unit = unit;
        // initialize other fields as needed
    }
}
