import React from "react";
import {List, ListItem, ListItemText} from "@mui/material";

const OpenOrders: React.FC = () => {
    const openOrders = [
        { id: 1, name: 'Order A', status: 'Processing' },
        { id: 2, name: 'Order B', status: 'Pending' },
        { id: 3, name: 'Order C', status: 'Shipped' },
    ];

    return (
        <List>
            {openOrders.map(order => (
                <ListItem key={order.id}>
                    <ListItemText primary={order.name} secondary={order.status} />
                </ListItem>
            ))}
        </List>
    );
};

export default OpenOrders;
