import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CwTextField from 'shared-components/src/ui/CwTextField';
import CwNumericField from 'shared-components/src/ui/CwNumericField';

import CwEmailTextField from 'shared-components/src/ui/CwEmailTextField';
import CwPhonenumberTextField from 'shared-components/src/ui/CwPhonenumberTextField';
import CwBirthdayPicker from 'shared-components/src/ui/CwBirthdayPicker';
import CwRadioGroup from 'shared-components/src/ui/CwRadioGroup';

const EditPlayground: React.FC = () => {
    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
    ];

    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwTextField</h3>
                        <CwTextField label="Some Text" />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwTextField required</h3>
                        <CwTextField label="Some Text" required />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwNumericField</h3>
                        <CwNumericField label="Some Text" />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwNumericField required</h3>
                        <CwNumericField label="Some Text"  required/>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwEmailTextField</h3>
                        <CwEmailTextField label="E-Mail" />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwEmailTextField required</h3>
                        <CwEmailTextField label="E-Mail" required />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwPhonenumberTextField</h3>
                        <CwPhonenumberTextField label="Telefonnummer" />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwPhonenumberTextField required</h3>
                        <CwPhonenumberTextField label="Telefonnummer" required />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>CwBirthdayPicker</h3>
                        <CwBirthdayPicker label="Geburtstag" />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>RadioGroup</h3>
                        <Box display="flex" justifyContent="center">
                            <CwRadioGroup label="Options" name="example" onChange={alert} options={options} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>RadioGroup horizontal</h3>
                        <Box display="flex" justifyContent="center">
                            <CwRadioGroup label="Options" name="example" onChange={alert} options={options} row />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EditPlayground;
