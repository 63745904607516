import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    Paper,
    IconButton,
    TextField,
    Typography,
    Collapse,
    Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { styled } from '@mui/material/styles';

interface Article {
    amount: number;
    articleNumber: string;
    articleName: string;
    pinYin: string;
    chinese: string;
    price: number;
}

interface OrderItemGridProps {
    articles: Article[];
    onDeleteArticle: (articleId: string) => void;
    onAmountChange: (articleId: string, amount: number) => void;
    onDragEnd: (result: DropResult) => void;
}

const DragHandleCell = styled(TableCell)({
    width: '2%',
    padding: 0,
    textAlign: 'center',
});

const AmountCell = styled(TableCell)({
    width: '6%',
    padding: 0,
    textAlign: 'center',
});

const DragHandle = styled(DragHandleIcon)({
    fontSize: '1rem',
});

const roundToSwiss = (value: number): number => {
    return Math.round(value * 20) / 20;
};

const OrderItemGrid: React.FC<OrderItemGridProps> = ({
                                                         articles,
                                                         onDeleteArticle,
                                                         onAmountChange,
                                                         onDragEnd,
                                                     }) => {
    const [expandedArticle, setExpandedArticle] = useState<string | null>(null);

    const handleInfoClick = (articleNumber: string) => {
        setExpandedArticle(expandedArticle === articleNumber ? null : articleNumber);
    };

    const handleDragStart = () => {
        setExpandedArticle(null);
    };

    const totalPrice = roundToSwiss(articles.reduce((sum, article) => sum + article.price, 0));

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
            <Droppable droppableId="articleList" direction="vertical">
                {(provided) => (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <DragHandleCell></DragHandleCell>
                                    <AmountCell>Amount</AmountCell>
                                    <TableCell>Article Number</TableCell>
                                    <TableCell>Article Name</TableCell>
                                    <TableCell>Pin Yin</TableCell>
                                    <TableCell>Chinese</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                                {articles.map((article, index) => (
                                    <React.Fragment key={article.articleNumber}>
                                        <Draggable key={article.articleNumber} draggableId={article.articleNumber} index={index}>
                                            {(provided, snapshot) => (
                                                <TableRow
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        backgroundColor: snapshot.isDragging ? '#f0f0f0' : 'inherit',
                                                        display: snapshot.isDragging ? 'table' : 'table-row',
                                                    }}
                                                >
                                                    <DragHandleCell {...provided.dragHandleProps}>
                                                        <DragHandle />
                                                    </DragHandleCell>
                                                    <AmountCell>
                                                        <TextField
                                                            type="number"
                                                            value={article.amount}
                                                            onChange={(e) =>
                                                                onAmountChange(article.articleNumber, parseInt(e.target.value))
                                                            }
                                                            fullWidth
                                                        />
                                                    </AmountCell>
                                                    <TableCell>{article.articleNumber}</TableCell>
                                                    <TableCell>{article.articleName}</TableCell>
                                                    <TableCell>{article.pinYin}</TableCell>
                                                    <TableCell>{article.chinese}</TableCell>
                                                    <TableCell>{article.price}</TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            aria-label="info"
                                                            onClick={() => handleInfoClick(article.articleNumber)}
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => onDeleteArticle(article.articleNumber)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                        <TableRow>
                                            <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <Collapse in={expandedArticle === article.articleNumber} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <Typography variant="h6">Article Information</Typography>
                                                        <Typography variant="body2">Details about the article {article.pinYin}...</Typography>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={5}></TableCell>
                                    <TableCell>
                                        <Typography variant="h6" align="right">Total CHF</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" align="left">{totalPrice.toFixed(2)}</Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default OrderItemGrid;
