import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import OrderOverviewArea from 'order/src/views/OrderOverviewArea';

const OrderPlayground: React.FC = () => {
    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box p={2} border={1} borderRadius={4} borderColor="grey.300">
                        <h3>Order</h3>
                        <OrderOverviewArea />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default OrderPlayground;
