import React from 'react';
import CwButton from 'shared-components/src/ui/CwButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

interface NavigationProps {
    onSelect: (component: string) => void;
    onToggle: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ onSelect, onToggle }) => {
    return (
        <div className="navigation">
            <div className="nav-header">
                <h3>Complemedis Komponenten</h3>
                <CwButton onClick={onToggle} className="nav-toggle-button">
                    <KeyboardDoubleArrowLeftIcon />
                </CwButton>
            </div>
            <div className="nav-buttons">
                UI Components
                <CwButton onClick={() => onSelect('buttons')}>Buttons</CwButton>
                <CwButton onClick={() => onSelect('edit')}>Edits</CwButton>
                <CwButton onClick={() => onSelect('search')}>Search</CwButton>
                <CwButton onClick={() => onSelect('tables')}>Tables</CwButton>
                <CwButton onClick={() => onSelect('diagram')}>Diagrams</CwButton>
                <CwButton onClick={() => onSelect('dialogs')}>Dialogs</CwButton>
                <CwButton onClick={() => onSelect('widgets')}>Widgets</CwButton>
                User Elements
                <CwButton onClick={() => onSelect('patientEditor')}>PatientEditor</CwButton>
                <CwButton onClick={() => onSelect('order')}>Order</CwButton>
            </div>
        </div>
    );
}

export default Navigation;
