import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const blue = {
  500: '#f3f0e2',
  600: '#f3f0a2',
  700: '#f3f0e2',
};

const CwButton = styled(Button)(({ theme }) => ({
  fontWeight: 'normal',
  fontSize: '1.2rem',
  backgroundColor: '#F3F0E2',
  padding: '12px 24px',
  borderRadius: '25px',
  color: 'black',
  transition: 'all 150ms ease',
  textTransform: 'none',

  cursor: 'pointer',
  border: 'none',
  '&:hover': {
    backgroundColor: '#F3F0E2',
  },
  '&:active': {
    backgroundColor: '#F3F0E2',
  },
  '&.Mui-focusVisible': {
    boxShadow: '0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5)',
    outline: 'none',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

export default CwButton;
