import { Box, Menu, MenuItem } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import CwTab from 'shared-components/src/ui/CwTab';
import CwTabs from 'shared-components/src/ui/CwTabs';
import OrderSummary from './OrderSummary';
import Order from './Order';
import { useAppDispatch, useAppSelector } from 'shared-components/src/app/hooks';
import { addOrder } from 'shared-components/src/features/orderSlice';
import IconGranulat from '../images/icons/icon_granulate_dunkel.png';
import IconRohdrogen from '../images/icons/icon_rohdrogen_dunkel.png';
import IconTabletten from '../images/icons/icon_tabletten_dunkel.png';
import { OrderType } from 'shared-components/src/interfaces/Enums';
import CwButton from 'shared-components/src/ui/CwButton';
import PatientArea from "./PatientArea";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box sx={{ padding: '1em' }}>
              {children}
            </Box>
        )}
      </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OrderOverviewArea: React.VFC = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const managedOrders = useAppSelector((state) => state.delivery.orders);
  const [value, setValue] = React.useState(managedOrders.length);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addNewOrder = (orderType: OrderType) => {
    dispatch(addOrder(orderType));
    setValue(managedOrders.length + 1);
  };

  const getIcon = (orderType: OrderType) => {
    switch (orderType) {
      case OrderType.Granulat:
        return <img height={25} src={IconGranulat} />;
      case OrderType.Rohdrogen:
        return <img height={25} src={IconRohdrogen} />;
      case OrderType.Tabletten:
        return <img height={25} src={IconTabletten} />;
      default:
        return <></>;
    }
  };

  const patient = {
    name: 'Bärtschi',
    preName: 'Matthias',
    street: 'Musterstrasse 123',
    zip: '5033',
    city: 'Buchs',
    phoneNumber: '123-456-7890',
    emailAddress: 'matthias.baertschi@example.com',
  };



  return (
      <div>
        <Box sx={{ width: '100%' }}>
          <PatientArea patient={patient} />
          <CwTabs value={value} onChange={handleChange} aria-label="orders">
              <CwTab value={0} {...a11yProps(0)} key={0} label="Bestellübersicht" />
              {managedOrders.map((order, i) => (
                  <CwTab
                      value={i + 1}
                      {...a11yProps(i + 1)}
                      key={i}
                      label={order.title ? order.title : 'Verschreibung'}
                      icon={getIcon(order.orderType)}
                  />
              ))}
            <CwButton onClick={handleClick}>+ Neue Bestellung</CwButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
            >
              <MenuItem onClick={() => addNewOrder(OrderType.Granulat)}>
                <img src={IconGranulat} height={30} />
                &nbsp;Granulat
              </MenuItem>
              <MenuItem onClick={() => addNewOrder(OrderType.Rohdrogen)}>
                <img src={IconRohdrogen} height={30} />
                &nbsp;Rohdrogen
              </MenuItem>
              <MenuItem onClick={() => addNewOrder(OrderType.Tabletten)}>
                <img src={IconTabletten} height={30} />
                &nbsp;Tabletten
              </MenuItem>
            </Menu>
          </CwTabs>

          <TabPanel value={value} index={0}>
            <OrderSummary />
          </TabPanel>
          {managedOrders.map((order, i) => (
              <TabPanel value={value} index={i + 1} key={i}>
                <Order id={order.id} orderType={order.orderType} />
              </TabPanel>
          ))}
        </Box>
      </div>
  );
};

export default OrderOverviewArea;
