import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Divider } from '@mui/material';

interface OrderCostsProps {
    customerType: number;
    costs: {
        totalArticlePrice: number;
        ebDiscount: number;
        nettoArticlePrice: number;
        workPrice: number;
        endpostPrice: number;
        pickupPrice?: number;
        totalPriceNoTax: number;
        mwstPrice: number;
        totalPrice: number;
    };
}

const OrderCosts: React.FC<OrderCostsProps> = ({ customerType, costs }) => {
    const { t } = useTranslation();

    return (
            <div className="row">
                <div className="pricearea">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography>{t('Warenwert')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{costs.totalArticlePrice.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{t('Eigenbedarfsrabatt 30%')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{costs.ebDiscount.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{t('Warenwert Netto')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{costs.nettoArticlePrice.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{t('Bearbeitung')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{costs.workPrice.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{t('Versandkosten')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{costs.endpostPrice.toFixed(2)}</Typography>
                        </Grid>
                        {(customerType !== 2 && customerType !== 4) && (
                            <>
                                <Grid item xs={6}>
                                    <Typography>{t('Abgabekosten')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography align="right">{costs.pickupPrice?.toFixed(2)}</Typography>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={6}>
                            <Typography>{t('Total exkl. Mwst')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{costs.totalPriceNoTax.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{t('Mwst 2.6%')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{costs.mwstPrice.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">{t('Total inkl. Mwst')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" align="right">{costs.totalPrice.toFixed(2)}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
    );
};

export default OrderCosts;
